import OA from './stories/assets/oa.png';
import assembly from './stories/assets/NeonAssemblly.png';
import mount from './stories/assets/MountMayhem.png';
import edu from './stories/assets/edu.jpg';
import wfh from './stories/assets/wfh.jpg';
import neon from './stories/assets/neonrush.png';
import fb from './stories/assets/fb.jpg';
import youtube from './stories/assets/youtube.png';
import insta from './stories/assets/insta.png';
import twit from './stories/assets/twit.png';
import jay from './stories/assets/jay.jpg';
import halen from './stories/assets/halen.jpg';
import assemblyM from './stories/assets/neonAssemblyMobile.jpg';
import mmm from './stories/assets/MMM.png';
import fbp from './stories/assets/fbp.jpg';
import pattern from './stories/assets/bg.svg';
interface Link {
    text: string,
    link: string,
};
export interface IArticle {
    type: 'Article',
    title?: string,
    img?: string,
    phoneImg?: string,
    subtitle?: string,
    text: string,
    links?: Link[],
}
export interface IListArticle {
    type:'ListArticle',
    title?:string,
    img?:string,
    body:IArticle[],
}
interface ISubtitle {
    type: 'subtitle',
    value: string,
}
interface ITitle {
    type: 'title',
    value: string
}
export interface IFoundersBlock {
    type: 'founders',
    title: string,
    img?:string,
    founders: FounderData[],
}
interface FounderData {
    name: string,
    description: string,
    img: string
}
export interface ISocialMedia {
    type: 'socials',
    title: string,
    body: Array<{img:string, link:string}>
}
export type Content = IArticle | ISubtitle | ITitle | IListArticle | IFoundersBlock | ISocialMedia;

export interface TitleLink {
    name: string,
    goto:() => void,
}
const Games:Content[] = [
    {
        type: 'title',
        value: 'Games'
    },
    {
        type: 'subtitle',
        value:'Commerical Games'
    },
    {
        type: 'Article',
        title: 'Operation Armstrong',
        img: OA,
        subtitle: 'The worlds first Assymetric VR Stealth Party Game',
        text: 'Your infiltration team must stop an army of lunar-bots from crashing the moon into our Earth! The field operative, wielding the VR headset, must sneak through each enemy outpost while their team of hackers will assist using their smartphones! Strap in for some Asymmetric-VR-Stealth-Party gameplay!',
        links: [
            {text: 'See more', link:'https://fullbeans.studio/oa'},
            {text: 'Steam Page', link: 'https://store.steampowered.com/app/1145490/Operation_Armstrong/'}
        ]
    },
    {
        type: 'subtitle',
        value:'Smaller Games'
    },
    {
        type: 'Article',
        title: 'Neon Rush',
        img: neon,
        subtitle: 'Community Game Jam 2019 (1 week) & Placed top 6%',
        text: 'What starts as a bullet hell where you are your own worst enemy rapidly turns to something much darker...',
        links: [
            {text: 'Play Here', link:'https://halberd63.itch.io/neon-rush'},
        ]
    },
    {
        type: 'Article',
        title: 'Mount Mayhem',
        img: mount,
        phoneImg:mmm,
        subtitle: 'GMTK Game Jam 2020 (2 days) & Placed top 12%',
        text: 'Cause as much carnage as you can playing as an out of control snowball as it barrels into the local resorts.',
        links: [
            {text: 'Play Here', link:'https://shaso.itch.io/mount-mayhem'},
        ]
    },
    {
        type: 'Article',
        title: 'Neon Assembly',
        phoneImg:assemblyM,
        img: assembly,
        subtitle: 'GMTK Game Jam 2021 (2 days) & Placed top 7%',
        text: 'Navigate through the cyberspace, joining salvaged enemy equipment with your ship. Your goal- disassemble the hacker leader.',
        links: [
            {text: 'Play Here', link:'https://halberd63.itch.io/neon-assembly'},
        ]
    },
]

const Services:Content[] = [
    {
        type:'title',
        value: 'Services'
    },
    {
        type: 'Article',
        title: 'Work for Hire',
        img: wfh,
        text: 'Need some more hands on your project? Our team has worked on large scale Unity projects and have experience in developing in a wide array of contexts from full stack web to building for virtual reality. Contact us with details of your project and we’ll get back to you with a hand-tailored response as to how we can help.',
        links: [
            {text: 'Contact us', link:'Contact'},
        ]
    },
    {
        type: 'Article',
        title: 'Educational Services',
        img: edu,
        text: 'Are you an educator looking for expertise on teaching game development? If so look no futher. We have developed and tested a robust Unity Cirriculum which will teach students 14+ the basics and offer a jumping off point for their game development journey.',
        links: [
            {text: 'Contact us', link:'Contact'},
        ]
    },
]
export const About:Content[] = [
    {
        type:'title',
        value:'About'
    },
    {
        type:'Article',
        title:'Who are we?',
        img: fb,
        phoneImg: fbp,
        text: 'Fullbeans Studio is a new Melbourne indie games studio formed by a pair of programmers who wanted to chase their dreams of becoming game developers. With a flair for the dramatic, the studio focuses on high intensity with the main design tenant of "There should be shouting".',
    },
    {
        type:'founders',
        title: 'Who started the studio?',
        img: fb,
        founders: [
            {
                name:'Halen Boyd',
                img: halen,
                description: 'Halen loves developing and optimizing smart algorithms for tough problems. He also has extensive experience in the education sector, teaching Unity and GameMaker development.'
            },
            {
                name:'Jay Wingate',
                img: jay,
                description: 'With a background in web development and hands on experience in HCI research, Jay delights in breaking down complex problems and building robust solutions.'
            }
        ]
    },
    {
        type:'ListArticle',
        title:'What do we stand for?',
        img: fb,
        body: [
            
            {
                type:'Article',
                title: 'People First',
                text: 'Creativity flourishes in supportive environment. We aim to provide an environment where individuals can excel at what they enjoy most.'
            },
            {
                type:'Article',
                title: 'Continual Improvement',
                text: 'We enjoy being on the cutting edge of technology. To do this, we constantly look to how we can improve our processes and tooling.'
            },
            {
                type:'Article',
                title: 'Stability',
                text: 'We are here and here to stay. People do their best work when they don’t have to worry about loosing their jobs.'
            }
        ]
    }
];

const Contact: Content[] = [
    {
        type:'title',
        value:'Contact'
    },
    {
        type:'ListArticle',
        title:'To get in touch',
        img:pattern,
        body: [
            {
                type:'Article',
                title: 'For Business mail:',
                text: 'Email: admin@fullbeans.studio'
            },
            {
                type:'Article',
                title: 'For Press',
                text: 'Email: press@fullbeans.studio'
            },
            {
                type:'Article',
                title: 'For Issue Reporting:',
                text: 'Email: bug.hunters@fullbeans.studio'
            }
        ]
    },
    {
        type:'socials',
        title:'Follow us on:',
        body: [
            {
                img: twit,
                link: "https://twitter.com/FullbeansStudio",
            },
            {
                img: insta,
                link: "https://www.instagram.com/fullbeansstudio/?hl=en",
            },
            {
                img: youtube,
                link: "https://www.youtube.com/channel/UCwGubgLGM2xyPz4DFD_38-Q",
            }
        ]
    }   
]

export const content = {
    title:"Fullbeans Studio",
    topics:[
        ...Games,  
        ...Services,
        ...About,
        ...Contact
    ]

}