import { useState } from 'react';
import styled from 'styled-components';
import {HeaderProps} from './FBHeader';
import Hamburger from './assets/Hamburger.svg';
export const Navbar = (props: HeaderProps) => (
    props.isPortait ? <MobileNavbar {...props}/>: <DesktopNavbar {...props}/>
)

const DesktopNavbar = (props:HeaderProps) => {
    return <DesktopWrapper>
        {props.titleList.map(l => <a onClick={l.goto}> {l.name} </a>)}
    </DesktopWrapper>
}
const MobileNavbar = (props:HeaderProps) => {
    const [isOpen, setOpen] = useState(false);
    const ToggleOpen = () => setOpen(!isOpen);
    return <MobileWrapper isOpen={isOpen}>
        <Menu>
        {props.titleList.map(l => <MobileMenuItem onClick={() => {ToggleOpen(); l.goto()}}> {l.name} </MobileMenuItem>)}
        </Menu>
        <HamburgerButton onClick={ToggleOpen}><img src={Hamburger}/></HamburgerButton>
    </MobileWrapper>
}
const MobileWrapper = styled.div<{isOpen:boolean}>`
    position: absolute;
    width: 70%;
    left: min( 130px, max(80px, 23vw));
    bottom: ${p => p.isOpen ? '-300px' : '-40px'};
    transition: bottom 0.5s ease-in-out;
`;
const MobileMenuItem = styled.a`
    padding:2%;
`;
const Menu = styled.div`
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    height:300px;
    display: flex;
    justify-content: flex-end;
    font-size: 4ex;
    align-items: flex-end;
    border-bottom-right-radius: 10px;
    font-family: 'Sniglet', cursive;
`;
const HamburgerButton = styled.div`
    position:relative;
    top:-1px;
    background-color: black;
    width: 7vh;
    min-width: 75px;
    height: 3vh;
    min-height: 50px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const DesktopWrapper = styled.div`
    position: absolute;
    top: max( 6vh, 40px);
    right: 0;
    height: 3vh;
    min-height:30px;
    width: 33%;
    background-color: black;
    border-bottom-left-radius: 17px;
    color:white;
    font-family: 'Sniglet', cursive;
    font-size: 3ex;
    display:flex;
    justify-content: space-evenly;
    align-items: center;
`;


