import React, { createRef, useState } from 'react';
import { FBHeader } from './stories/FBHeader';
import {BG} from './stories/Bg';
import {ContentContainer, Subtitle, TestObj, Title} from './stories/ContentContainer';
import styled from 'styled-components';
import {Article, ListArticle} from './stories/Article';
import { content, Content, TitleLink} from './content';
import { FoundersBlock} from './stories/FoundersBlock';
import {Socials} from './stories/Socials';
const checkIsPortrait = () => (window.innerWidth + 200) < window.innerHeight;



function App() {

  const [isPortrait, setPortrait] = useState<boolean>(checkIsPortrait());
  const [bgSource, setBgSource] = useState<string | undefined>();
  const [bgBackup, setBgBackup] = useState<string | undefined>();
  
  const scrollEventListeners:Array<() => void> = [];
  const state = {
    setBgBackup,
    setBg: setBgSource,
    isPortrait,
    scrollListener:(func:()=>void) => {scrollEventListeners.push(func)},
  }
  const titleList:TitleLink[] = [];

  const gotoSection = (s:string) => {
    const link = titleList.find(l => l.name.toLowerCase() == s.toLowerCase())
    console.log("test", titleList, link, s);
    if(link)
      link.goto()
  }; 
  const drawContent = (content:Content, index:number) => {
    const isSelected =(bgSource || bgBackup) == (content as any).img;
    switch(content.type){
      case "Article":
        return <Article key={index} gotoSection={gotoSection}{...state} {...content} isSelected={isSelected}/>
      case "title":
        const ref:React.RefObject<HTMLDivElement> = createRef();
        titleList.push({name:content.value, goto: () => ref.current?.scrollIntoView({behavior: "smooth"})});
        return <Title ref={ref} key={index} isPortrait={isPortrait}>{content.value}</Title>
      case "subtitle":
        return  <Subtitle key={index} isPortrait={isPortrait}>{content.value}</Subtitle>
      case "ListArticle":
        return <ListArticle key={index} {...state} {...content} isSelected={isSelected}/>
      case "founders":
        return <FoundersBlock key={index} {...state} {...content} isSelected={isSelected}/>
      case "socials":
        return <Socials key={index} {...state} {...content}  isSelected={isSelected}/>
      }
  }

  React.useEffect(() => {
      const resizeHandler = () => {
          const currentlyPortait = checkIsPortrait();
          const isNoLongerPortrait = isPortrait && !currentlyPortait;
          const isNowPortrait = !isPortrait && currentlyPortait;
          if( isNoLongerPortrait || isNowPortrait )
              setPortrait(currentlyPortait)
      }
      window.addEventListener('resize', resizeHandler);
  })
  const Contents = content.topics.map(drawContent);

  return (
    <Wrapper>
      <BG source={bgSource || bgBackup}/>
      <FBHeader isPortait={isPortrait} titleList={titleList}/>
      <ContentContainer isPortrait={isPortrait} onScroll={() => scrollEventListeners.forEach(f => {f()})}>
          {Contents}
          <TestObj/>
      </ContentContainer>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  width:100vw;
  height:100vh;
  max-width:100%;
  overflow:hidden;
  position:relative;
`;
export default App;
