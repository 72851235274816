import React from "react";
import styled from "styled-components";
import { ISocialMedia } from '../content';
import {ArticleWrapper,Title } from './Article';
interface SocialProps extends ISocialMedia {
    isPortrait: boolean;
    isSelected: boolean;
}
export const Socials = (props:SocialProps) => {
    return <ArticleWrapper isPortrait={props.isPortrait} isSelected={true}>
        <Title isPortrait={props.isPortrait}>{props.title}</Title>
        <FlexWrapper >
            {props.body.map((e,i) => (
                <a href={e.link}><Icon src={e.img}/></a>
            ))}
        </FlexWrapper>
       
    </ArticleWrapper>
}
const FlexWrapper =styled.div`
    display:flex;
    justify-content: space-around;
    padding-top: 2%;
`;
const Icon = styled.img`
    width:  100px;
    height: 100px;
    border-radius:100%;
`;