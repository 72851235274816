import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import bg from './assets/bg.svg';

interface Props {
    source?: string;
}
export const BG = (props: Props) => {
    const [img, setImg] = useState<string>(props.source || bg);
    const [tranistioing, setTransitioning] = useState<boolean>(false);
    useEffect(() => {
        setTransitioning(true);
        setTimeout(() => {
            setTransitioning(false)
            setImg(props.source || bg)
        }, 200);
    }, [props.source])
    return <Background src={img} tranistioing={tranistioing} repeat={img === bg}/>
}

const Background = styled.div<{src:string, repeat: boolean, tranistioing: boolean}>`
    transition: opacity 0.2s linear;
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-repeat:repeat;
    opacity:${p => p.tranistioing ? 0 : 1};
    background-image:url('${p => p.src}');
     
    ${p => !p.repeat && css`
            background-repeat: no-repeat;
            background-size: cover;
        `}  
    
`;