import styled from 'styled-components';
import { TitleLink } from '../content';
import Logo from './assets/Logo.png';
import {Navbar} from './Navbar';

export interface HeaderProps {
    isPortait: boolean,
    titleList:TitleLink[]
}

export const FBHeader = (props: HeaderProps) => (
    <Wrapper>
        <Navbar {...props}/>
        <TopBarBG/>
        <Content>
            <IconContainer>
                <Icon src={Logo}/>
            </IconContainer>
            <Title>Fullbeans Studio</Title>
        </Content>
        
    </Wrapper>)

const Wrapper = styled.div`
    width: 100vw;
    position:fixed;
    top:0;
    left:0;
    display:flex;
    z-index:1000;
    max-width:100%;

`;

const TopBarBG = styled.div`
    width: 100%;
    background-color: white;
    border-bottom: black solid;
    height:6vh;
    min-height:40px;
    max-height:100px;
    position:relative;
`;
const Content = styled.div`
    position:absolute;
    width:100%;
    height:6vh;
    display:flex;
    max-height:100px;
    min-height:40px;
`;
const IconContainer = styled.div`
    min-height:100px;
    min-width:100px;
    width: 25vmin;
    max-height: 150px;
    max-width: 150px;
    height: 25vmin;
    display: flex;
    margin-top:5px;
    background-color:white;
    border-radius:100%;
    border: black solid;
`;
const Icon = styled.img`
    width: 55%;
    max-height: 130px;
    margin: auto;
    padding: 0 28%;
`;
const Title = styled.span`
    padding-left:2%;
    display: flex;
    align-items: center;
    font-family: 'Sniglet', cursive;
    font-size: min( 71px, max( 28px ,5vw));
`;