import React from "react";
import styled from "styled-components";
import { IFoundersBlock } from '../content';
import {ArticleWrapper, Description, Subtitle, Title} from './Article';
interface FoundersProps extends IFoundersBlock {
    isPortrait: boolean;
    setBg: (newBg?: string) => void;
    setBgBackup: (newBg?: string) => void;
    scrollListener:(func:()=>void) => void;
    isSelected: boolean;
}
export const FoundersBlock = (props:FoundersProps) => {
    return <ArticleWrapper isPortrait={props.isPortrait} onMouseEnter={() => !props.isPortrait && props.setBg(props.img)} isSelected={props.isSelected}>
        <Title isPortrait={props.isPortrait}>{props.title}</Title>
        {props.founders.map((e,i) => (
            <FounderBlock isOdd={i % 2 === 0}>
                <FounderImage src={e.img}/>
                <FoundersData>
                {e.name && <Subtitle {...props}> {e.name} </Subtitle>}
                <Description {...props}>{e.description}</Description>
                </FoundersData>
            </FounderBlock>
        ))}
    </ArticleWrapper>
}
const FounderBlock = styled.div<{isOdd: boolean}>`
    display:flex;
    flex-direction: ${p => p.isOdd? 'row' : 'row-reverse'};
    align-items: center;
`;
const FoundersData = styled.div`
    padding:1%;
    
    margin: 3%;
`;
const FounderImage = styled.img`
    width:100px;
    height:100px;
    border-radius:100%;
    margin:4%;
`;