import styled, { css } from 'styled-components';

export const ContentContainer = styled.div<{isPortrait: boolean}>`
    background-color: white;
    width: 40%;
    height: 94vh;
    position: absolute;
    right: 5%;
    overflow-x: visible;
    overflow-y: scroll;
    top:6vh;
    padding: 0 1% 0 1%;
    ${p => p.isPortrait && css`
        width:100%;
        right:0;
        padding:0;
        background-color:#0000;
    `}
`;

export const Title = styled.div<{isPortrait: boolean}>`
    font-family: 'Sniglet', cursive;
    font-size: 5ex;

    ${p => p.isPortrait && css`
        text-align: right;
        background-color: black;
        font-size: 4ex;
        color: white;
        padding: 0 2%;
        position: relative;
        width: 73%;
        left: 23%;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    `}
`;

export const Subtitle = styled(Title)`
    font-size:4ex;
    ${p => p.isPortrait && css`
        visibility:hidden;
        position:absolute;
    `}
`;
export const TestObj = styled.div`
    height: 45%;

`;