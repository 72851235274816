import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { IArticle, IListArticle } from '../content';

function isValidHttpUrl(string:string) {
    try {
        new URL(string);
    } catch (_) {
        return false;  
    }
    return true;
}

interface ArticleProps extends IArticle{
    isPortrait: boolean;
    setBg: (newBg?: string) => void;
    setBgBackup: (newBg?: string) => void;
    gotoSection: (sectionName:string) => void;
    scrollListener:(func:()=>void) => void;
    isSelected: boolean;
}

export const Article = (props: ArticleProps) => {

    const mouseOver =() => !props.isPortrait && props.setBg(props.img)
    const mouseOut = () => !props.isPortrait && props.setBg();
    const ref = useRef<HTMLDivElement>(null);
    const onScroll = () => {
        if(!ref.current) return null;
        const bbox = ref.current.getBoundingClientRect();
        if(!props.isPortrait && bbox.y < 200 && 200 < (bbox.y + bbox.height))
            props.setBgBackup(props.img);
    }
    useEffect(() => {
        props.scrollListener(onScroll)
    },[props.scrollListener])
    
    return (
    <ArticleWrapper
        isSelected={props.isSelected}
        ref={ref}
        isPortrait={props.isPortrait}
        onMouseEnter={mouseOver}
        onMouseLeave={mouseOut}
        onScroll={onScroll as any}
    >

        {props.isPortrait && <Image src={props.phoneImg || props.img}/>}
        <Title isPortrait={props.isPortrait}>{props.title}</Title>
        {props.subtitle && props.subtitle.split('&').map((d,i)=> <Subtitle key={i} isPortrait={props.isPortrait}>{d}</Subtitle>)}
        {props.text.split('&').map((d,i)=><Description isPortrait={props.isPortrait}>{props.text}</Description>)}
        {props.links && <LinkTab>
            {props.links.map(l => (
                <a 
                    href={isValidHttpUrl(l.link) ? l.link : undefined} 
                    onClick={() => props.gotoSection(l.link) }>
                        <Link>{l.text}</Link>
                </a>
            ))}
        </LinkTab>}
    </ArticleWrapper>)

}

interface ListArticleProps extends IListArticle{
    isPortrait: boolean;
    setBg: (newBg?: string) => void;
    setBgBackup: (newBg?: string) => void;
    scrollListener:(func:()=>void) => void
    isSelected: boolean;
}

export const ListArticle = (props:ListArticleProps) => {
    return (
        <ArticleWrapper 
            isPortrait={props.isPortrait}
            onMouseEnter={() => props.img && !props.isPortrait && props.setBg(props.img)}
            onMouseLeave={() => props.img && !props.isPortrait && props.setBg()}
            isSelected={props.isSelected}   
        >
        <Title isPortrait={props.isPortrait}>{props.title}</Title>
        {props.body.map((e,i) => (
            <SubArticle 
                onMouseEnter={() => e.img && !props.isPortrait && props.setBg(e.img)}
                onMouseLeave={ () => e.img && !props.isPortrait && props.setBg()}
            >
                {e.title && <Subtitle {...props}> {e.title} </Subtitle>}
                <Description {...props}>{e.text}</Description>
            </SubArticle>
        ))}
    </ArticleWrapper>)
}



const SubArticle = styled.div`
    padding:3%;
`;
const LinkTab = styled.div`
    padding: 3%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
`;
const Link = styled.button`
    border: none;
    background-color: #5ec1f9;
    color: white;
    border-radius: 7px;
    padding: 10px;
    font-size: 3ex;
    font-family: 'Sniglet';
`;
export const ArticleWrapper = styled.div<{isSelected:boolean, isPortrait: boolean}>`
    padding: 3%;
    color: ${p => p.isPortrait || p.isSelected? "black" : "grey"};
    ${p => p.isPortrait && css`
        margin: 4%;
        padding: 2%;
        background-color:white;
        border-radius:2vmax;
    `}
`;
const Image = styled.img`
    width:98%;
    margin:1%;
    border-radius:5%;
`;
export const Title = styled.div<{isPortrait: boolean}>`
    font-family: 'Sniglet', cursive;
    font-size: 4ex;

    ${p => p.isPortrait && css`
        text-align:center;
    `}
`;
export const Subtitle = styled.div<{isPortrait: boolean}>`
    font-family: 'Sniglet', cursive;
    font-size: 2.5ex;
`;
export const Description = styled.div<{isPortrait: boolean}>`

`;